body {
  background-color: #282c34;
  color: white;
}

p {
  font-size: 3vmin;
}

.centerCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bottomRight {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px;
}
.bottomLeft {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 5px;
}
.sideRight {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 5px;
  z-index: 1;
}

.sideLadderWrapper {
  font-size: 1.8vmin;
  margin-top: auto;
  margin-bottom: auto;
  //max-height: calc(100vh - 10px);
  max-height: 100vh;
  padding-left: 75px;
  overflow-x: visible;
  overflow-y: scroll;
  scrollbar-width: none;
}
.sideLadderWrapper::-webkit-scrollbar {
  display: none;
}
.sideLadderItem {
  padding: 5px;
  transform: skew(15deg);
  border-left: 10px solid rgba(255,255,255,0.2);
  transition: all 450ms ease;
}
.sideLadderItem > span {
  display: inline-block;
  transform: skew(-15deg);
  pointer-events: none;
}
.sideLadderItem:hover {
  cursor: pointer;
  background: slategrey;
  transform: translateX(-25px);
  transition: all 150ms ease;
}
.sideLadderItemDelay {
  position: absolute;
  transform: translateX(-65px) !important;
}
.sideLadderItemDelay > span {
  font-size: 0.5vw;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.portfolioButton {
  color: white;
  display: inline-block;
  padding: 10px;
  border: 2px solid rgba(0,0,0,0);
  border: 2px solid darkgrey;
  transition: background 1200ms, border 1200ms;
  margin: 5px;
}
.portfolioButton:hover {
  border: 2px solid rgba(0,0,0,0);
  border-right: 2px solid darkgrey;
  background: darkslategrey;
  transition: background 1200ms, border 1200ms;
  transition-timing-function: ease;
  cursor: pointer;
}

.outer {
  position: relative;
  width: 150px;
  height: 150px;
}

.markerBoxes {
  position: absolute;
  left: -15px;
  top: -10px;
}

.rotatingBoxes1 {
  border: 1px solid grey;
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotatingBoxesAnim1 2500ms ease infinite;
}
@keyframes rotatingBoxesAnim1 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.rotatingBoxes2 {
  border: 1px solid lightgrey;
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotatingBoxesAnim2 2250ms ease infinite;
}
@keyframes rotatingBoxesAnim2 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.rotatingBoxes3 {
  border: 1px solid darkgrey;
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotatingBoxesAnim3 2000ms ease infinite;
}
@keyframes rotatingBoxesAnim3 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.rotatingBoxes::before_ {
  content: '';
  display: inline-block;
  border: 1px solid blue;
  width: 150px;
  height: 150px;
  left: -50%;
  transform: rotate(45deg);
  animation: rotatingBoxesAnim 2500ms ease infinite;
}
.rotatingBoxes::after_ {
  content: '';
  display: inline-block;
  position: absolute;
  border: 1px solid red;
  width: 150px;
  height: 150px;
  transform: translateX(-100px) rotate(22.5deg);
  animation: rotatingBoxesAnim 2500ms ease infinite;
}

.hamburgerHeader {
  position: fixed;
  padding-top: 10px;
  left: 15px;
  z-index: 1;
}

.mapHeader {
  border-top: 1px solid grey;
  padding: 5px;
  margin: 25px;
  height: 20px;
  width: calc(100vw - 70px);
  background: rgba(255,255,255,0.1);
  position: fixed;
  top: 0;
  color: white;
  z-index: 1;
  /*clip-path: polygon(
    40px 0,
    100% 0,
    calc(100% - 40px) 100%,
    0 100%
  );*/
}
.mapHeader > a > div {
  float: right;
}

.testMarker {
  z-index: 1;
  display: inline-block;
  background: black; 
  border: 1px solid lightgrey;
  max-width: 30vw;
  //max-height: 30vh;
  padding: 10px;
  font-size: 1.5vmin;
  font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace; 
  overflow-y: none;
  white-space: nowrap;
}
.testMarker:hover {
  cursor: pointer;
}
.markerWrapper {
  border: 1px solid green;
}

@keyframes locationAnim {
  0% { filter: blur(20px); transform: translateY(40px); opacity: 0;} 
  100% { filter: blur(0px); transform: translateY(0px); opacity: 1;}
}

.locationPaperWrapper {
  padding: 5px;
  margin-bottom: 40px;
  width: 40vw;
  backdrop-filter: blur(10px);
}

.locationPaperWrapper::before {
  content: '';
  display: block;
  position: absolute;
  border: 1px solid rgba(255,255,255,0.2);
  width: 95px;
  height: 150%;
  transform: translate(5px, -5px) skew(-15deg);
  z-index: -1;
}

.locationPaperRegion {
  font-size: 1vw;
  color: slategrey;
  animation: locationAnim 2500ms ease;
  animation-fill-mode: both;
  animation-delay: 250ms;
}

.locationPaperReal {
  font-size: 4vw;
  height: 100%;
  overflow: visible;
  animation: locationAnim 2500ms ease;
}

.locationPaperReal::after {
  display: block;
  content: '';
  border-bottom: 1px solid grey;
  width: 125%;
  margin-bottom: 5px;
}

.locationPaperCoordinates {
  font-size: 1.5vw;
  float: right;
  color: slategrey;
  animation: locationAnim 2500ms ease;
  animation-fill-mode: both;
  animation-delay: 650ms;
}

.rootModal {
  width: 50vw;
  height: 50vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid lightgrey;
  background: black;
  padding: 15px;
  animation: rootModalAnim 950ms cubic-bezier(.88,.13,.1,1.39);
  font-size: 2vmin;
  overflow: scroll;
}

.rootModalHeader {
  font-size: 8vmin;
  border-left: 2px solid lightgrey;
  padding: 5px;
}

.rootModalSubHeader {
  font-size: 6vmin;
  padding-top: 10px;
}

@keyframes rootModalAnim {
  0% { width: 0px; height: 0px; overflow: hidden; content:''; color: black; }
  25% { width: 0px; height: 85%; overflow: hidden; content:''; color: black; }
  100% { overflow: hidden; }
}
